<template>
  <div class="row">
    <div class="col col-lg8 home">
      <div class="jumbotron" style="margin-left:10%;margin-top:3%">
        <h1 class="display-4">Hello There</h1>
        <p class="lead">I am a Full Stack Java developer working in Turkey's leading telecommunication company.</p>
        <hr class="my-4">
        <p class="info">My abilities are not limited to just java. I also have developed myself in Angular and Vue-js.<br>
          <br> If you are interested, you can take a look at my resume and projects.</p>
        <p class="lead">
          <router-link class="btn btn-primary btn-light" style="margin-left: 1%;border: darkblue;border-style:solid"
                       to="/resume">See My Resume
          </router-link>
          <router-link class="btn btn-primary btn-light" style="margin-left: 1%;border: darkblue;border-style:solid"
                       to="/projects">See My Projects
          </router-link>
        </p>
      </div>
      <div class="col col-lg4">
        <img alt="cuppeli" class="image"
             src="https://res.cloudinary.com/mertyalcin/image/upload/v1662751806/homepage_qe9dsw.png"/>

      </div>
    </div>

  </div>

</template>

<script>
export default {
  name: "HomePage",
}
</script>

<style scoped>
.home {
  align-content: center;
  display: flex;
  min-height: 590px;
}

.image {
  height: 580px
}

.info {
  font-family: "Times New Roman";
  font-size: larger;
}

@media screen and (max-width: 800px) {
  .image {
    display: none;
  }
}
</style>
