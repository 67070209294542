<template>
  <v-app>
    <v-main>
      <header-part></header-part>
      <router-view/>
      <footer-part></footer-part>
    </v-main>
  </v-app>
</template>

<script>

import HeaderPart from "@/components/Header";
import FooterPart from "@/components/Footer";
export default {
  name: 'App',
  components: {FooterPart, HeaderPart},
  data: () => ({
    //
  }),
}
</script>
