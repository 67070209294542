<template>
  <div>
    <nav class="navbar navbar-expand-lg bg-light">
      <div class="container-fluid">
        <router-link class="navbar-brand head" to="/">Mert Yalçın</router-link>

        <button aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation" class="navbar-toggler"
                data-bs-target="#navbarNavAltMarkup" data-bs-toggle="collapse" type="button">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div id="navbarNavAltMarkup" class="collapse navbar-collapse">
          <div id="nav" class="navbar-nav">
            <router-link class="nav-link" to="/resume">Resume</router-link>
            <router-link v-if="true" class="nav-link" to="/projects">Projects</router-link>
            <router-link class="nav-link" to="/contact">Contact</router-link>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: "HeaderPart"
}
</script>

<style scoped>
.head {
  font-family: "Head";
  font-size: 23px;
}

@font-face {
  font-family: "Head";
  src: local("Head"),
  url(./fonts/head.ttf) format("truetype");
}
</style>
