import { createRouter, createWebHistory } from 'vue-router'
import Home from '../components/Home.vue'
const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/contact',
        name: 'ContactPage',
        component: () => import('../components/Contact.vue')
    },
    {
        path: '/projects',
        name: 'Project',
        component: () => import('../components/Project.vue')
    },
    {
        path: '/resume',
        name: 'Resume',
        component: () => import('../components/Resume.vue')
    },
    {
        path: '/error',
        name: 'ErrorPage',
        component: () => import('../components/Error.vue')
    },

    {
        path: '/*',
        name: 'HomePage',
        component: Home
    },
]
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})
export default router
