import { createApp } from 'vue'
import App from './App.vue'
import PrimeVue from 'primevue/config';
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'
import 'primevue/resources/themes/saga-blue/theme.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import router from "@/router";
import axios from "axios";

import { loadFonts } from './plugins/webfontloader'
loadFonts()
/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
/* import specific icons */
import { faJava } from '@fortawesome/free-brands-svg-icons'
import { faVuejs } from '@fortawesome/free-brands-svg-icons'
import { faHtml5 } from '@fortawesome/free-brands-svg-icons'
import { faAngular } from '@fortawesome/free-brands-svg-icons'
import { faCss3 } from '@fortawesome/free-brands-svg-icons'
import { faGithub } from '@fortawesome/free-brands-svg-icons'
import { faCode } from '@fortawesome/free-solid-svg-icons'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import vuetify from "@/plugins/vuetify";
/* add icons to the library */
library.add(faJava)
library.add(faVuejs)
library.add(faHtml5)
library.add(faAngular)
library.add(faCss3)
library.add(faCode)
library.add(faGithub)
library.add(faPhone)
library.add(faEnvelope)
library.add(faLinkedin)
axios.defaults.baseURL= 'https://mert-yalcin-backend.herokuapp.com/api'
axios.defaults.headers.common['Authorization'] ="Basic " + btoa('mert:mert')
/*axios.defaults.baseURL= 'http://localhost:6378/api'*/

axios.defaults.headers.common["Content-Type"]= 'application/json'

import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
const tostOption = {
    position: "top-right",
    timeout: 5000,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: true,
    closeButton: "button",
    icon: true,
    rtl: false,

    transition: "Vue-Toastification__bounce",
    maxToasts: 20,
    newestOnTop: true
};



import store from "./store";


createApp(App).use(store).use(Toast, tostOption).component('font-awesome-icon', FontAwesomeIcon).use(vuetify).use(PrimeVue).use(router).mount('#app')


