<template>
  <div class="container">
    <footer class="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
      <div class="col-md-4 d-flex align-items-center">
        <a class="mb-3 me-2 mb-md-0 text-muted text-decoration-none lh-1" href="/">
          <svg class="bi" height="24" width="30">
            <use xlink:href="#bootstrap"/>
          </svg>
        </a>
        <span class="text-muted">&copy; 2022 Mert Yalçın</span>
      </div>

      <ul class="nav col-md-4 justify-content-end list-unstyled d-flex">

        <li class="ms-3"><a :href="linkedin" class="text-muted" target="_blank">
          <font-awesome-icon icon="fa-brands fa-linkedin" style="width: 24px;height: 36px"/>
        </a></li>
        <li class="ms-3"><a :href="github" class="text-muted" target="_blank">
          <font-awesome-icon icon="fa-brands fa-github" style="width: 24px;height: 36px"/>
        </a></li>
        <li class="ms-3"><a v-tooltip="'eng.mertyalcin@gmail.com'" :href="'mailto: '+email" class="text-muted">
          <font-awesome-icon icon="fa-solid fa-envelope" style="width: 24px;height: 36px"/>
        </a></li>
        <li class="ms-3"><span v-tooltip="'+90 531 223 66 93'" class="text-muted"><font-awesome-icon
            icon="fa-solid fa-phone" style="width: 24px;height: 36px"/></span></li>
      </ul>

    </footer>
  </div>

</template>

<script>

/*import axios from "axios";*/
import Tooltip from 'primevue/tooltip';

export default {

  name: 'FooterPart',
  data() {
    return {
      linkedin: 'https://www.linkedin.com/in/mertyalcin-metu/',
      email: 'eng.mertyalcin@gmail.com',
      github: 'https://github.com/mertyalcin-code',
      phone: '+90 531 223 66 93',
    }
  },
  props: {},
  components: {},

  directives: {
    'tooltip': Tooltip
  },
  created() {
    /*axios.get("/parameter/get-by-name?name=LINKEDLN_URL",{
      auth: {
        username: "admin",
        password: "admin"
      }}).then(response => {
      if(response.data.success){
        this.linkedin=response.data.data.value;
      }
    })

    axios.get("/parameter/get-by-name?name=PHONE").then(response => {

      if(response.data.success){
        this.phone=response.data.data.value;
      }
    })

    axios.get("/parameter/get-by-name?name=GITHUB_URL").then(response => {

      if(response.data.success){
        this.github=response.data.data.value;
      }
    })

    axios.get("/parameter/get-by-name?name=EMAIL_URL").then(response => {

      if(response.data.success){
        this.email=response.data.data.value;
      }
    })*/
  },

}

</script>


<style scoped>
.b-example-divider {
  height: 3rem;
  background-color: rgba(0, 0, 0, .1);
  border: solid rgba(0, 0, 0, .15);
  border-width: 1px 0;
  box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15);
}

.bi {
  vertical-align: -.125em;
  fill: currentColor;
}

.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
}
</style>
